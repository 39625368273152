NOTES = ["A",	"A#",	"B",	"C",	"C#",	"D",	"D#",	"E",	"F",	"F#",	"G",	"G#"]
A0 = 3.4375
OCTAVE0 = -4
COEF_NOTE = 2**(1/12.0)
LOG_COEF_NOTE = Math.log(2**(1/12.0))

/*get_deviation_from_ref = function(f,f_ref){
    return Math.round(10000*((f/f_ref)-1)/(COEF_NOTE-1))/100;
}*/

get_tone_octave_cent = function(f){
    var octave = OCTAVE0;
    var f_0 = A0;
    var note = 0;
    while (f > f_0*2){
        octave += 1;
        f_0 *= 2;
    }
    var f_1 = f_0;
    while (f > f_1*(COEF_NOTE)) {
        note += 1;
        f_1 *= (COEF_NOTE);
    }
    var f_2 = f_1*(COEF_NOTE);
    var cents;
    if (f > (f_1+f_2)/2.0) {
        note += 1;
        cents = 100 * (f - f_2) / (f_2 - f_1);
    } else {
        cents = 100 * (f - f_1) / (f_2 - f_1);
    }
    if (note == 12) {
        note = 0;
        octave += 1;
    } else if (note > 2) {
        octave += 1;
    }
    return [NOTES[note],octave,Math.round(cents*10)/10];
}




