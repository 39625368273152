CURVE_CANVAS_WIDTH_GAP = 40;
CURVE_CANVAS_HEIGHT = 270;
ORIGIN_COORD = {'x':40,'y':40}
CANVAS_MARGIN_TOP = 8;
CANVAS_MARGIN_RIGHT = 35;


MIN_HZ = 20;
MAX_HZ = 1000;
SUGGESTED_MAX_IMP = 18;
SUGGESTED_MAX_DB = 80;
SUGGESTED_IMP_TICK = 1;
SUGGESTED_DB_TICK = 10;
SUGGESTED_HZ_TICK = 100;
MIN_GAP_X_TICKS = 50;
MIN_GAP_Y_TICKS = 30;
GAP_RATIO_AXIS_Y = 0.15

var chart = null;

udpate_pitch_curves = function(segments){
    reset_ze_res();
    var points = generate_impedance_curve_points(segments);
    var maximum = extract_curve_max(points);
    maximum = raffine_maximum_imp(maximum,segments);
    update_notes(points,segments,maximum);
    if (maximum.length < 1){
        maximum=[MAX_HZ];
    }
    var points_harm = generate_harmonics_curve_points(segments,points,maximum[0]['x']);
    update_curves(points,points_harm,maximum);
}

update_notes = function(points,segments,maximum){
    var nbr_max = maximum.length;
    var sign;
    if (nbr_max > 0){
        var info0 = get_tone_octave_cent(maximum[0]['x']);
        if(info0[2] >= 0){
          sign = "+"
        } else {
          sign = ""
        }
        document.getElementById("freq_0_didg").innerHTML = Math.round(maximum[0]['x']*10)/10 + " Hz";
        document.getElementById("note_0_didg").innerHTML = info0[0] + "" + info0[1];
        document.getElementById("dev_0_didg").innerHTML = sign + Math.round(info0[2]*10)/10 + "%";
        document.getElementById("imp_0_didg").innerHTML = Math.round(maximum[0]['y']*10)/10 + " MOhm";
    } else {
        document.getElementById("freq_0_didg").innerHTML = "";
        document.getElementById("note_0_didg").innerHTML = "";
        document.getElementById("dev_0_didg").innerHTML = "";
        document.getElementById("imp_0_didg").innerHTML = "";
    }
    if (nbr_max > 1){
        var info1 = get_tone_octave_cent(maximum[1]['x']);
        if(info1[2] >= 0){
          sign = "+"
        } else {
          sign = ""
        }
        document.getElementById("freq_1_didg").innerHTML = Math.round(maximum[1]['x']*10)/10 + " Hz";
        document.getElementById("note_1_didg").innerHTML = info1[0] + "" + info1[1];
        document.getElementById("dev_1_didg").innerHTML = sign + Math.round(info1[2]*10)/10 + "%";
        document.getElementById("imp_1_didg").innerHTML = Math.round(maximum[1]['y']*10)/10 + " MOhm";
    } else {
        document.getElementById("freq_1_didg").innerHTML = "";
        document.getElementById("note_1_didg").innerHTML = "";
        document.getElementById("dev_1_didg").innerHTML = "";
        document.getElementById("imp_1_didg").innerHTML = "";
    }
    if (nbr_max > 2){
        var info2 = get_tone_octave_cent(maximum[2]['x']);
        if(info2[2] >= 0){
          sign = "+"
        } else {
          sign = ""
        }
        document.getElementById("freq_2_didg").innerHTML = Math.round(maximum[2]['x']*10)/10 + " Hz";
        document.getElementById("note_2_didg").innerHTML = info2[0] + "" + info2[1];
        document.getElementById("dev_2_didg").innerHTML = sign + Math.round(info2[2]*10)/10 + "%";
        document.getElementById("imp_2_didg").innerHTML = Math.round(maximum[2]['y']*10)/10 + " MOhm";
    } else {
        document.getElementById("freq_2_didg").innerHTML = "";
        document.getElementById("note_2_didg").innerHTML = "";
        document.getElementById("dev_2_didg").innerHTML = "";
        document.getElementById("imp_2_didg").innerHTML = "";
    }
    if (nbr_max > 3){
        var info3 = get_tone_octave_cent(maximum[3]['x']);
        if(info3[2] >= 0){
          sign = "+"
        } else {
          sign = ""
        }
        document.getElementById("freq_3_didg").innerHTML = Math.round(maximum[3]['x']*10)/10 + " Hz";
        document.getElementById("note_3_didg").innerHTML = info3[0] + "" + info3[1];
        document.getElementById("dev_3_didg").innerHTML = sign + Math.round(info3[2]*10)/10 + "%";
        document.getElementById("imp_3_didg").innerHTML = Math.round(maximum[3]['y']*10)/10 + " MOhm";
    } else {
        document.getElementById("freq_3_didg").innerHTML = "";
        document.getElementById("note_3_didg").innerHTML = "";
        document.getElementById("dev_3_didg").innerHTML = "";
        document.getElementById("imp_3_didg").innerHTML = "";
    }
}

update_curves = function(points,points_harm,maximum){
    var canvas = document.getElementById("curve_canvas");
    var ctx = canvas.getContext("2d");
    if(ctx) {
        canvas.width = document.getElementById("curve_canvas_wrapper").offsetWidth - CURVE_CANVAS_WIDTH_GAP;
        canvas.height = CURVE_CANVAS_HEIGHT;
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        draw_curve_canvas(canvas,ctx,points,points_harm,maximum);
    }
}

draw_curve_canvas = function(canvas,ctx,points,points_harm,maximum) {
    var origin = origin_canvas_coord(canvas);
    var max_axis = get_max_axis(points);
    var max_axis_harm = get_max_axis_harm(points_harm);
    var canvas_x = canvas.width-origin['x']-CANVAS_MARGIN_RIGHT;
    var canvas_y = origin['y']-CANVAS_MARGIN_TOP;
    var canvas_size = {'x':canvas_x,'y':canvas_y};


    draw_axis(canvas,ctx,canvas_size,origin,max_axis,max_axis_harm);
    var canvas_points = update_point_coord(points,canvas,canvas_size,origin,max_axis);
    var canvas_points_harm = update_point_coord(points_harm,canvas,canvas_size,origin,max_axis_harm);
    dram_maximums(canvas,ctx,origin,update_point_coord(maximum,canvas,canvas_size,origin,max_axis),maximum);
    draw_curve(canvas,ctx,origin,canvas_points,"#ff0000");
    draw_curve(canvas,ctx,origin,canvas_points_harm,"#0000ff");
}

dram_maximums = function(canvas,ctx,origin,maximum,maximum_raw) {
    var i;
    for (i=0;i<maximum.length;i++){
        ctx.strokeStyle = "#bbbbbb";
        vertical_dash(ctx,{'x':maximum[i]['x'],'y':origin['y']+13},{'x':maximum[i]['x'],'y':CANVAS_MARGIN_TOP})
        ctx.strokeStyle = "#000000";
        ctx.font = "10px Helvetica";
        ctx.fillText(maximum_raw[i]['x'] + " Hz",maximum[i]['x']-15,origin['y']+22);
        var info = get_tone_octave_cent(maximum_raw[i]['x'])
        if(info[2]>=0){
            info[2] = "+" + info[2]
        }
        ctx.font = "12px Helvetica";
        ctx.fillText(info[0] + "" + info[1] + " (" + info[2] + "%)",maximum[i]['x']+3,Math.max(maximum[i]['y']-2,11));
        ctx.font = "10px Helvetica";
    }
}

vertical_dash = function(ctx,p1,p2,full=5,space=5){
    var y = p1['y'];
    while (y+full > p2['y']){
        ctx.beginPath();
        ctx.moveTo(p1['x'],y);
        ctx.lineTo(p1['x'],y-full);
        ctx.stroke()
        y -= full+space
    }
    if (y > p2['y']) {
        ctx.beginPath();
        ctx.moveTo(p1['x'],y);
        ctx.lineTo(p1['x'],p2['y']);
        ctx.stroke()
    }
}

draw_axis = function(canvas,ctx,canvas_size,origin,max_axis,max_axis_harm){
    ctx.strokeStyle = "#000000";
    ctx.beginPath();
    ctx.moveTo(origin['x'],origin['y']);
    ctx.lineTo(origin['x'],CANVAS_MARGIN_TOP);
    ctx.lineTo(origin['x']-5,CANVAS_MARGIN_TOP+5);
    ctx.stroke();
    ctx.lineTo(origin['x'],CANVAS_MARGIN_TOP);
    ctx.lineTo(origin['x']+5,CANVAS_MARGIN_TOP+5);
    ctx.stroke();

    var dist = MIN_GAP_X_TICKS;
    var tick = Math.round(max_axis['x']*(dist/canvas_size['x'])/100)*100;
    if (tick < SUGGESTED_HZ_TICK){
        tick = SUGGESTED_HZ_TICK;
    }
    dist = tick * canvas_size['x']/max_axis['x'];
    var d=0,t = 0;
    while(t <= max_axis['x']){
        ctx.beginPath();
        ctx.moveTo(origin['x']+d,origin['y']);
        ctx.lineTo(origin['x']+d,origin['y']+5);
        ctx.stroke();
        ctx.fillText(t,origin['x']+d-8,origin['y']+13);
        t += tick;
        d += dist;
    }
    ctx.fillText("Hertz (Hz)",origin['x']+canvas_size['x']/2 - 20,origin['y']+30);


    ctx.moveTo(origin['x'],origin['y']);
    ctx.lineTo(canvas.width-CANVAS_MARGIN_RIGHT,origin['y']);
    ctx.stroke();

    dist = MIN_GAP_Y_TICKS;
    tick = Math.round(max_axis['y']*(dist/canvas_size['y']));
    if (tick < SUGGESTED_IMP_TICK){
        tick = SUGGESTED_IMP_TICK;
    }
    dist = tick * canvas_size['y']/max_axis['y'];
    var d=0,t = 0;
    while(t <= max_axis['y']){
        ctx.beginPath();
        ctx.moveTo(origin['x'],origin['y']-d);
        ctx.lineTo(origin['x']-5,origin['y']-d);
        ctx.stroke();
        ctx.fillText(t,origin['x']-18,origin['y']-d+5);
        t += tick;
        d += dist;
    }
    ctx.save();
    ctx.translate(origin['x']-25,origin['y']-canvas_size['y']/2);
    ctx.rotate(-Math.PI/2);
    ctx.textAlign = "center";
    ctx.fillText("Impédance (MOhm)",0, 0);
    ctx.restore();

    ctx.moveTo(origin['x']+canvas_size['x'],origin['y']);
    ctx.lineTo(origin['x']+canvas_size['x'],CANVAS_MARGIN_TOP);
    ctx.lineTo(origin['x']+canvas_size['x']-5,CANVAS_MARGIN_TOP+5);
    ctx.stroke();
    ctx.lineTo(origin['x']+canvas_size['x'],CANVAS_MARGIN_TOP);
    ctx.lineTo(origin['x']+canvas_size['x']+5,CANVAS_MARGIN_TOP+5);
    ctx.stroke();

    dist = MIN_GAP_Y_TICKS;
    tick = Math.round(max_axis_harm['y']*(dist/canvas_size['y'])/10)*10;
    if (tick < SUGGESTED_DB_TICK){
        tick = SUGGESTED_DB_TICK;
    }
    dist = tick * canvas_size['y']/max_axis_harm['y'];
    var d=0,t = 0;
    while(t <= max_axis_harm['y']){
        ctx.beginPath();
        ctx.moveTo(origin['x']+canvas_size['x'],origin['y']-d);
        ctx.lineTo(origin['x']+canvas_size['x']+5,origin['y']-d);
        ctx.stroke();
        ctx.fillText(t,origin['x']+canvas_size['x']+8,origin['y']-d+5);
        t += tick;
        d += dist;
    }
    ctx.save();
    ctx.translate(origin['x']+canvas_size['x']+32,origin['y']-canvas_size['y']/2);
    ctx.rotate(-Math.PI/2);
    ctx.textAlign = "center";
    ctx.fillText("Volume (dB)",0, 0);
    ctx.restore();
    ctx.globalAlpha = 0.4;
    ctx.lineWidth = 3;
    ctx.strokeStyle = "#ff0000";
    ctx.beginPath();
    ctx.moveTo(origin['x']+10,CANVAS_MARGIN_TOP+5);
    ctx.lineTo(origin['x']+30,CANVAS_MARGIN_TOP+5);
    ctx.fillText("Impédance",origin['x']+35,CANVAS_MARGIN_TOP+5);
    ctx.stroke();
    ctx.strokeStyle = "#0000ff";
    ctx.moveTo(origin['x']+10,CANVAS_MARGIN_TOP+20);
    ctx.lineTo(origin['x']+30,CANVAS_MARGIN_TOP+20);
    ctx.fillText("Harmonique",origin['x']+35,CANVAS_MARGIN_TOP+20);
    ctx.stroke();
    ctx.lineWidth = 1;
    ctx.globalAlpha = 1;

}

draw_curve = function(canvas,ctx,origin,points,color="#000000"){
    ctx.globalAlpha = 0.4;
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(points[0]['x'],origin['y']);
    var i;
    for(i=0;i<points.length;i++){
        ctx.lineTo(points[i]['x'],points[i]['y']);
    }
    ctx.lineTo(points[points.length-1]['x'],origin['y']);
    ctx.lineTo(points[0]['x'],origin['y']);
    ctx.fill();
    ctx.globalAlpha = 1;
}

get_max_axis = function(points) {
    var max_x = 0,max_y = 0;
    var i;
    for (i=0;i<points.length;i++){
        if (points[i]['x'] > max_x){
            max_x = points[i]['x'];
        }
        if (points[i]['y'] > max_y){
            max_y = points[i]['y'];
        }
    }
    max_y = max_y*(1+GAP_RATIO_AXIS_Y);
    if (max_y < SUGGESTED_MAX_IMP){
        max_y = SUGGESTED_MAX_IMP;
    }
    return {'x':max_x,'y':max_y}
}

get_max_axis_harm = function(points) {
    var max_x = 0,max_y = 0;
    var i;
    for (i=0;i<points.length;i++){
        if (points[i]['x'] > max_x){
            max_x = points[i]['x'];
        }
        if (points[i]['y'] > max_y){
            max_y = points[i]['y'];
        }
    }
    max_y = max_y*(1+GAP_RATIO_AXIS_Y);
    if (max_y < SUGGESTED_MAX_DB){
        max_y = SUGGESTED_MAX_DB;
    }
    return {'x':max_x,'y':max_y}
}

origin_canvas_coord = function(canvas){
    return {'x':ORIGIN_COORD['x'],'y':canvas.height-ORIGIN_COORD['y']};
}

update_point_coord = function(points,canvas,canvas_size,origin_canvas_coord,max_axis){
    var res = [];
    var i;
    for (i=0;i<points.length;i++){
        res.push(coord_to_canvas_coord(points[i],canvas,canvas_size,origin_canvas_coord,max_axis))
    }
    return res;
}

coord_to_canvas_coord = function(coord,canvas,canvas_size,origin_canvas_coord,max_axis){
    return {'x':canvas_size['x']*coord['x']/max_axis['x'] + origin_canvas_coord['x'],
        'y':canvas_size['y']-canvas_size['y']*coord['y']/max_axis['y'] + CANVAS_MARGIN_TOP};
}

/*
update_curves = function(points){
    var canvas = document.getElementById("curve_canvas");
    var ctx = canvas.getContext("2d");
    var datas = updated_chart_datasets(points);
    var scales = udpate_chart_scales();
    if(ctx) {
        canvas.width = document.getElementById("curve_canvas_wrapper").offsetWidth - CURVE_CANVAS_WIDTH_GAP;
        canvas.height = CURVE_CANVAS_HEIGHT;
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        if (chart){
            chart.data.datasets = datas;
            chart.options.scales = scales;
            chart.update({
                duration: 0
            });
        } else {
            chart = new Chart(ctx, {
                type: 'line',
                data: {
                    datasets: datas
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: scales,
                    elements: {
                        point:{
                            backgroundColor: 'rgba(0, 0, 0, 0.0)',
                            borderColor: 'rgba(0, 0, 0, 0.0)',
                            radius: 5
                        }
                    },
                    legend: {
                        display: false
                    },

                }
            });
        }
    }
}

updated_chart_datasets = function(points){
    var datas = [];
    datas.push({
        label: "Impédance",
        data: points,
        backgroundColor: 'rgba(124, 0, 4, 0.4)',
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        fill: true
    });
    return datas;
}

udpate_chart_scales = function(){
    var scales = {
        yAxes: [{
            position: 'left',
            scalePositionLeft: true,
            ticks: {
                beginAtZero: true,
                suggesterMin:0,
                suggestedMax:SUGGESTED_MAX_IMP
            },
            scaleLabel: {
                display: true,
                labelString: 'Impédance (MOhm)'
            }
        }],
        xAxes: [{
            type: 'linear',
            ticks: {
                beginAtZero: true,
                suggesterMin:0,
                suggestedMax:MAX_HZ
            },
            scaleLabel: {
                display: true,
                labelString: 'Fréquence (Hz)'
            }
        }]
    }
    return scales;
}*/
