/*
Viscosity https://www.engineersedge.com/physics/viscosity_of_air_dynamic_and_kinematic_14483.htm
*/

/*
segments[i] = [length_horizontal,diam_in,diam_out]
*/

const math = require("mathjs");

ACCURATE_OVERTONE = 6;
TEMP = 40;
VISCOSITY = {0 : 1.729e-5, 5 : 1.754e-5, 10 : 1.778e-5, 15 : 1.802e-5, 20 : 1.825e-5, 25 : 1.849e-5, 30 : 1.872e-5, 35 : 1.895e-5, 40 : 1.918e-5};
P = 1.292*273.15/(TEMP+273.15);

if (VISCOSITY.hasOwnProperty(TEMP))
    N = VISCOSITY[TEMP];
else
    N = VISCOSITY[20];
C = 331.3 + 0.606*TEMP;

var ze_res = {};

reset_ze_res = function(){
    ze_res = {};
}

f_to_w= function (f) {
    return 2.0 * Math.PI * f;
}

ap = function(w,segments) {
    var x = [[1, 0], [0, 1]];
    var y = [[0, 0], [0, 0]];
    var z = [[1, 0], [0, 1]];
    var i,length,diam_0,diam_1,a0,r0,rvw,kw,tw,zcw,phi,l,x0,x1,csinhlwl,ccoshlwl,csinhlwL,ccoshlwL;
    for (i=0;i<segments.length;i++){
        length = segments[i][0] / 1000.0;
        diam_0 = segments[i][1] / 1000.0;
        diam_1 = segments[i][2] / 1000.0;
        a0 = Math.PI * diam_0 * diam_0 / 4.0;
        r0 = P * C / a0;
        rvw = Math.sqrt(P * w / N) * (diam_0 + diam_1) / 4.0;
        kw = w / C;
        tw = math.complex(kw * 1.045 / rvw, kw * (1.0 + 1.045 / rvw));
        zcw = math.complex(r0 * (1.0 + 0.369 / rvw), -r0 * 0.369 / rvw);

        if (diam_1 != diam_0){
            phi = Math.atan((diam_1 - diam_0) / (2.0 * length));
            l = (diam_1 - diam_0) / (2.0 * Math.sin(phi));
            x1 = diam_1 / (2.0 * Math.sin(phi));
            x0 = x1 - l;

            ccoshlwl = math.cosh(math.multiply(tw, l));
            csinhlwl = math.sinh(math.multiply(tw, l));
            y[0][0] = math.multiply(x1 / x0, math.subtract(ccoshlwl, math.divide(csinhlwl, (math.multiply(tw, x1)))));
            y[0][1] = math.multiply(x0 / x1, math.multiply(zcw, csinhlwl));
            y[1][0] = math.divide(math.add(math.multiply(math.subtract(x1 / x0, math.divide(1.0, math.pow(math.multiply(tw, x0), 2))), csinhlwl), math.divide(math.multiply(math.multiply(tw, l), ccoshlwl), math.pow(math.multiply(tw, x0), 2))), zcw);
            y[1][1] = math.multiply(x0 / x1, math.add(ccoshlwl, math.divide(csinhlwl, (math.multiply(tw, x0)))));
        }
        else {
            ccoshlwL = math.cosh(math.multiply(tw, length));
            csinhlwL = math.sinh(math.multiply(tw, length));

            y[0][0] = ccoshlwL;
            y[0][1] = math.multiply(zcw, csinhlwL);
            y[1][0] = math.divide(csinhlwL, zcw);
            y[1][1] = ccoshlwL;
        }
        z[0][0] = math.add(math.multiply(x[0][0], y[0][0]), math.multiply(x[0][1], y[1][0]));
        z[0][1] = math.add(math.multiply(x[0][0], y[0][1]), math.multiply(x[0][1], y[1][1]));
        z[1][0] = math.add(math.multiply(x[1][0], y[0][0]), math.multiply(x[1][1], y[1][0]));
        z[1][1] = math.add(math.multiply(x[1][0], y[0][1]), math.multiply(x[1][1], y[1][1]));

        x[0][0] = z[0][0];
        x[0][1] = z[0][1];
        x[1][0] = z[1][0];
        x[1][1] = z[1][1];
    }
    return z;
}

za = function(w,segments){
    var segment_info,length,diam_0,diam_1,rvw,a0,r0,zcw,res;
    segment_info = segments[segments.length-1];
    length = segment_info[0]/1000.0;
    diam_0 = segment_info[1]/1000.0;
    diam_1 = segment_info[2]/1000.0;
    rvw = (diam_0 + diam_1) * Math.sqrt(P * w/N)/4.0;
    a0 = Math.PI * diam_0 * diam_0 / 4.0;
    r0 = P * C / a0;
    zcw = math.complex(r0 * (1.0 + 0.369/rvw),-r0 * 0.369/rvw);
    /*
    #    alert(zcw)
    #    alert(w)
    #    alert(diam_1)
    #    alert(C)
    #    alert(0.5  * w * w * diam_1 * diam_1/C/C)
    #    alert('info ' + math.multiply(0.5  * w * w * diam_1 * diam_1/C/C, zcw) + "   " + math.multiply((math.complex(0,1)),math.multiply(0.5 * 0.61 * length * w * diam_1 / C, zcw)))
    */

    res = math.add(math.multiply(0.5  * w * w * diam_1 * diam_1/C/C, zcw), math.multiply((math.complex(0,1)),math.multiply(0.5 * 0.61 * w * diam_1 / C, zcw)));
    return res;
}

ze = function(f,segments) {
    if (! ze_res.hasOwnProperty(f)) {
        var w,a,b,res;
        w = f_to_w(f);
        a = za(w,segments);
        b = ap(w,segments);
        res = math.divide(math.divide(math.add(math.multiply(a, b[0][0]), b[0][1]), math.add(math.multiply(a, b[1][0]), b[1][1])), 1000000.0);
        res = math.abs(res);
        res = Math.round(1000 * res) / 1000;
        ze_res[f] = res;
    }
    return ze_res[f];
}

generate_impedance_curve_points = function(segments){
    var i,points = [];
    for (i=MIN_HZ;i<=MAX_HZ;i++){
        points.push({'x':i,'y':ze(i,segments)})
    }
    return points;
}

generate_harmonics_curve_points = function(segments,points_imp,max_0_imp) {
    var points = [];
    var k = 0.0001;
    var m = Math.round(max_0_imp);
    var i, f, sound_specturm, j1, j2, n,s,tmp;
    for (i = 0; i < points_imp.length; i++) {
        f = points_imp[i]['x'];
        if (f < m) {
            sound_specturm = ze(f,segments) * 1e6 * (Math.E ** (m * k));
        } else {
            j1 = f % m;
            n = (f - j1) / m;
            j2 = j1 - m;
            if (j1 == 0) {
                sound_specturm = ze(m,segments) * 1e6 * (Math.E ** ((n + 1) * m * k)) + ze(m,segments) * 1e6 * (Math.E ** (n * m * k))
            } else {
                sound_specturm = ze(m + j2,segments) * 1e6 * (Math.E ** ((n + 1) * m * k)) + ze(m - j1,segments) * 1e6 * (Math.E ** (n * m * k))
            }
        }
        points.push({'x': f, 'y': sound_specturm});
    }
    for (i = 0; i < points.length; i++) {
        f = points[i]['x'];
        s = ze(f,segments) * points[i]['y'];
        tmp = 20 * Math.log(s* 2e-5)/Math.log(10);
        if (tmp < 0) {
            points[i]['y'] = 0;
        } else {
            points[i]['y'] = tmp;
        }
    }
    return points;
}

extract_curve_max = function(points){
    var i,res = [];
    for(i=1;i<points.length-1;i++){
        if(points[i-1]['y'] < points[i]['y'] && points[i]['y'] >= points[i+1]['y']){
            if(i<10 || points[i-10]['y'] < points[i]['y']) {
                if((points.length-i) < 12 || points[i]['y'] >= points[i+10]['y']) {
                    res.push(points[i]);
                }
            }
        }
    }
    return res;
}

raffine_maximum_imp = function(points,segments){
    var i,j,val,tmp,res=[];
    for (i=0;i<points.length;i++){
        tmp = points[i];
        if (i <= ACCURATE_OVERTONE) {
            for (j = -9; j < 10; j++) {
                val = ze(points[i]['x'] + j * 0.1, segments);
                if (val > tmp['y']) {
                    tmp = {'x': points[i]['x'] + j * 0.1, 'y': val};
                }
            }
        }
        res.push(tmp);
    }
    return res;
}
