DIAM_MIN = 20;
DIAM_MAX = 260;
EMBOUCHURE_MIN = DIAM_MIN;
EMBOUCHURE_MAX = 80;
EMBOUCHURE_DEFAULT = 40;
LENGTH_MIN = 1;
LENGTH_MAX = 2000;
LENGTH_DEFAULT = 500;
DIAM_CANVAS_MAX_DEFAULT = 180;
DIAM_CANVAS_PADDING = 70;
LENGTH_CANVAS_MAX_DEFAULT = 1800;
LENGTH_CANVAS_PADDING = 100;
LENGTH_UNIT_DEFAULT = "cm";

CANVAS_HEIGHT = 120;
DIDG_CANVAS_WIDTH_GAP = 40;
DIDG_CANVAS_RATIO = 2;  /* y_coord = ratio * y_mesure*/

HOST = "";

var length_unit;

var didg_dimensions;

var history;
var history_id;

var section_edit_id;

var draw_active = false;
var draw_id_active = null;
var draw_last_coord = null;
var new_point = null;
var point_moved = null;
var void_point = null;

define_csts = function(host) {
    HOST = host
}

coef_length_unit = function(){
    var coef=1;
    if (length_unit == "cm"){
        coef=0.1;
    }
    return coef;
}

init_length_unit = function() {
    length_unit = LENGTH_UNIT_DEFAULT;
}

init_de_embouchure = function() {
    didg_dimensions['embouchure'] = EMBOUCHURE_DEFAULT;
    var elem = document.getElementById("section_diam_0");
    var option;
    var i;
    for (i = DIAM_MIN; i <= EMBOUCHURE_MAX; i++) {
        option = document.createElement("option");
        option.text = i.toString();
        elem.add(option);
        if (i==EMBOUCHURE_DEFAULT){
            elem.value = i.toString();
        }
    }
    elem.addEventListener("change",function (event){
        set_start_diam(parseInt(event.currentTarget.value));
        didg_dimension_update_callback();
    });
}

init_de_sections = function() {
    didg_dimensions['sections'] = [[LENGTH_DEFAULT,EMBOUCHURE_DEFAULT]];
}

extract_id = function(string){
    var tmp = string.split('_')
    return parseInt(tmp[tmp.length-1]);
}

init_de_embouchure_section = function() {
    didg_dimensions = new Object();
    init_de_embouchure();
    init_de_sections();
    update_section_selects();
}


init_de_history = function() {
    history_id = -1;
    history = [];
}

init_de_event_listeners = function(){
    var elem = document.getElementById("add_section_button");
    elem.addEventListener("click",add_section);
    elem = document.getElementById("remove_section_button");
    elem.addEventListener("click",remove_section);
    elem = document.getElementById("unit_length");
    elem.addEventListener("change",update_units);
    elem = document.getElementById("unit_length_section");
    elem.addEventListener("change",update_units_section);
    elem = document.getElementById("new_didg_btn");
    elem.addEventListener("click",new_didg);
    elem = document.getElementById("print_didg_btn");
    elem.addEventListener("click",print_didg);
    elem = document.getElementById("previous_didg_btn");
    elem.addEventListener("click",restore_last_didg);
    elem = document.getElementById("next_didg_btn");
    elem.addEventListener("click",restore_next_didg);
    elem = document.getElementById("add_section_btn");
    elem.addEventListener("click",add_section);
    elem = document.getElementById("remove_current_section");
    elem.addEventListener("click",function(){
      if (nbr_sections() > 1){
        if(section_edit_id == 1){
          set_start_diam(didg_dimensions['sections'][0][1]);
        }
        didg_dimensions['sections'].splice(section_edit_id-1,1);
        if(section_edit_id > nbr_sections()){
          section_edit_id = nbr_sections()
        }
        didg_dimension_update_callback();
      }
    });
    elem = document.getElementById("previous_section");
    elem.addEventListener("click",function(){
      if(section_edit_id > 1){
        section_edit_id -= 1;
        didg_dimension_update_callback();
      }
    });
    elem = document.getElementById("next_section");
    elem.addEventListener("click",function(){
      if(section_edit_id < nbr_sections()){
        section_edit_id += 1;
        didg_dimension_update_callback();
      }
    });
    init_de_event_listeners_canvas();
    init_de_event_listeners_section_edtion();
}

init_de_event_listeners_section_edtion = function() {
    var elem = document.getElementById("section_edition_length");
    elem.addEventListener("change",function (event){
        var coef = coef_length_unit()
        set_section_length(Math.round(parseInt(event.currentTarget.value)/coef),section_edit_id);
        didg_dimension_update_callback();
    });
    elem = document.getElementById("section_edition_width");
    elem.addEventListener("change",function (event){
        var diff,diam_in,diam_out,width;
        if (section_edit_id == 1){
          diam_in = get_start_diam();
        } else {
          diam_in = get_section_end_diam(section_edit_id-1);
        }
        diam_out = get_section_end_diam(section_edit_id);
        width = Math.round((diam_out+diam_in)/2);
        diff = parseInt(event.currentTarget.value) - width;
        if (section_edit_id == 1){
          set_start_diam(diam_in+diff);
        } else {
          set_section_end_diam(diam_in+diff,section_edit_id-1);
        }
        set_section_end_diam(diam_out+diff,section_edit_id);
        didg_dimension_update_callback();
    });
    elem = document.getElementById("section_edition_diam_in");
    elem.addEventListener("change",function (event){
        if (section_edit_id == 1){
          set_start_diam(parseInt(event.currentTarget.value));
        } else {
          set_section_end_diam(parseInt(event.currentTarget.value),section_edit_id-1);
        }
        didg_dimension_update_callback();
    });
    elem = document.getElementById("section_edition_diam_out");
    elem.addEventListener("change",function (event){
        set_section_end_diam(parseInt(event.currentTarget.value),section_edit_id);
        didg_dimension_update_callback();
    });
}

init_de_event_listeners_canvas = function() {
    var elem = document.getElementById("didg_canvas");
    elem.addEventListener("mousedown",start_canvas_edition);
    elem.addEventListener("mousemove",canvas_mousemove);
    elem.addEventListener("mouseup",stop_canvas_edition);
    elem.addEventListener("mouseleave",stop_canvas_edition);
}

new_didg = function() {
    var i;
    for (i = nbr_sections();i>1;i--){
        delete_section(i);
    }
    set_start_diam(EMBOUCHURE_DEFAULT);
    set_section([LENGTH_DEFAULT,EMBOUCHURE_DEFAULT],1);
    section_edit_id = 1;
    didg_dimension_update_callback();
}

sleep = function (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

didg_dimensions_to_post = function(){
  var info = "embouchure=" + get_start_diam() + "&nbr=" + nbr_sections();
  var i;
  for (i=1; i<=nbr_sections();i++){
    info += "&l" + i + "=" + get_section_length(i) + "&d" + i + "=" + get_section_end_diam(i);
  }
  return info
}

print_didg = function() {
    //document.getElementById("screenshot_wrapper").style.display = "block";
    var scale = 0.96;
    document.getElementById("didg_canvas_wrapper").style.boxShadow = "none";
    document.getElementById("curve_canvas_wrapper").style.boxShadow = "none";
    html2canvas(document.querySelector("#main_content"),{
      scale: scale
    }).then(canvas => {
      var imgData = canvas.toDataURL("image/png", 1.0);
      var pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save("DidgEdit-model.pdf");
      var url = HOST + "/edit";
      var info = didg_dimensions_to_post();
      document.getElementById("print_config_info").value = info;
      var form = document.getElementById("print_form");
      form.submit();
      document.getElementById("didg_canvas_wrapper").style.boxShadow = "3px 3px 3px black";
      document.getElementById("curve_canvas_wrapper").style.boxShadow = "3px 3px 3px black";

      // window.location = HOST;
      // Rails.ajax({
      //   url : url,
      //   type : "POST",
      //   data : info
      // });
    });
    // sleep(1000).then(() => {
    //
    //   // var contentWidth = canvas.width;
    //   // var contentHeight = canvas.height;
    //   //
    //   // //One page pdf shows the height of canvas generated by html page;
    //   // var pageHeight = contentWidth / 592.28 * 841.89;
    //   // //html page height without pdf generation
    //   // var leftHeight = contentHeight;
    //   // //Page offset
    //   // var position = 0;
    //   // //a4 paper size [595.28841.89], width and height of image in pdf of canvas generated by html page
    //   // var imgWidth = 595.28;
    //   // var imgHeight = 592.28/contentWidth * contentHeight;
    //   //
    //   // //Return picture dataURL, parameters: picture format and sharpness (0-1)
    //   // var pageData = canvas.toDataURL('image/png', 1.0);
    //   //
    //   // //Direction is vertical by default, dimension ponits, format A4 [595.28841.89]
    //   // var pdf = new jsPDF('', 'pt', 'a4');
    //   // alert("ok")
    //   // //There are two heights to distinguish, one is the actual height of the html page, and the height of the generated pdf page (841.89)
    //   // //When the content does not exceed the display range of one page of pdf, paging is not required
    //   // if (leftHeight < pageHeight) {
    //   //     pdf.addImage(pageData, 'PNG', 0, 0, imgWidth, imgHeight );
    //   // } else if(pageHeight > 0){
    //   //     while(leftHeight > 0) {
    //   //         pdf.addImage(pageData, 'PNG', 0, position, imgWidth, imgHeight)
    //   //         leftHeight -= pageHeight;
    //   //         position -= 841.89;
    //   //         //Avoid adding blank pages
    //   //         if(leftHeight > 0) {
    //   //           pdf.addPage();
    //   //         }
    //   //     }
    //   // }
    //   // pdf.save("DidgEdit-model.pdf");
    // });
    //document.getElementById("screenshot_wrapper").display = "none";
}

start_canvas_edition = function(event){
    draw_active = true
    var coord = click_canvas_to_didg_coord(event)
    var geometry = dimensions_to_didg_coord()
    if (point_after_didg(coord,geometry,DIAM_MIN/2)){
        insert_section([Math.round(coord[0]-geometry[geometry.length-1][0]),Math.round(coord[1]*2)],nbr_sections()+1)
        draw_id_active = nbr_sections()
        draw_last_coord = coord
        new_point = true
        point_moved = false
        didg_dimension_update_callback(false);
    } else if (point_before_didg(coord,geometry,DIAM_MIN/2,EMBOUCHURE_MAX/2)){
        add_start_section(Math.round(geometry[0][0]-coord[0]),Math.round(coord[1]*2))
        draw_id_active = 0
        draw_last_coord = coord
        new_point = true
        point_moved = false
        section_edit_id += 1
        didg_dimension_update_callback(false);
    } else{
        var id = coord_on_point(coord,geometry);
        if (id != -1){
            draw_id_active = id
            draw_last_coord = coord
            new_point = false
            point_moved = false
        } else {
            id = coord_on_edge(coord, geometry);
            if (id != -1) {
                var sec, sec2;
                var section = get_section(id)
                var l = Math.round(coord[0] - geometry[id - 1][0])
                var w = Math.round(2*(geometry[id - 1][1] + (l/section[0])*(geometry[id][1] - geometry[id - 1][1])))
                sec = [l, w]
                sec2 = [section[0] - l, section[1]]
                set_section(sec, id)
                insert_section(sec2, id + 1)
                draw_id_active = id
                draw_last_coord = coord
                new_point = true
                point_moved = false
                if (id < section_edit_id){
                  section_edit_id += 1
                }
                didg_dimension_update_callback(false);
            } else{
              void_point = coord;
            }

        }
    }
}

canvas_mousemove = function(event){
    point_moved = true
    if (draw_id_active != null && draw_last_coord != null){
        var coord = click_canvas_to_didg_coord(event);
        var diff = [coord[0]-draw_last_coord[0],coord[1]-draw_last_coord[1]];
        var sec_x = null,sec_y = null,sec2_x = null;
        var sec2 = null,sec = null;
        if (draw_id_active > 0){
            sec = get_section(draw_id_active)
            sec_x = sec[0] + Math.round(diff[0]);
            sec_y = sec[1] + Math.round(2*diff[1]);
        } else {
            sec_y = get_start_diam() + Math.round(2*diff[1]);
        }
        if (draw_id_active < nbr_sections()){
            sec2 = get_section(draw_id_active+1);
            sec2_x = sec2[0] - Math.round(diff[0]);
        }
        if (((draw_id_active == 0 && sec_y >= EMBOUCHURE_MIN && sec_y <= EMBOUCHURE_MAX) || (draw_id_active > 0 && sec_x >= LENGTH_MIN && sec_y >= DIAM_MIN && sec_y <= DIAM_MAX)) && (sec2_x == null || sec2_x >= LENGTH_MIN)){
            if(sec != null) {
                sec[0] = sec_x;
                sec[1] = sec_y;
            } else if (draw_id_active == 0) {
                set_start_diam(sec_y)
            }
            if(sec2 != null){
                sec2[0] = sec2_x;
            }
            draw_last_coord = coord;
            didg_dimension_update_callback(false);
        }
    }
}

stop_canvas_edition = function(event){
    if (draw_active){
        if (draw_id_active != null && !new_point && !point_moved){
            var sec=null,sec2=null
            if (draw_id_active > 0){
                sec = get_section(draw_id_active)
            }
            if (draw_id_active < nbr_sections()){
                sec2 = get_section(draw_id_active+1)
            }
            if (sec != null){
                if (sec2 != null){
                    delete_section(draw_id_active)
                    set_section_length(sec[0]+sec2[0],draw_id_active)
                } else if (nbr_sections() > 1){
                    delete_section(nbr_sections())
                }
            } else if (sec2 != null && nbr_sections() > 1){
                set_start_diam(get_section(1)[1])
                delete_section(1)
            }
            if (draw_id_active < section_edit_id){
              if (section_edit_id > 1) {
                section_edit_id -= 1
              }
            } else {
              if (section_edit_id > nbr_sections()){
                section_edit_id -= 1
              }
            }
        } else if (void_point != null){
          var geometry = dimensions_to_didg_coord()
          var id = point_in_section(void_point,geometry);
          if(id != -1){
            section_edit_id = id;
          }
        }
        didg_dimension_update_callback();
    }
    draw_active = false;
    draw_id_active = null;
    draw_last_coord = null;
    new_point = null;
    point_moved = null;
    void_point = null;
}

init_section_edit = function() {
    section_edit_id = 1;
}

init_didg_edition = function (didg_edition_init) {
    init_length_unit();
    init_de_embouchure_section();
    init_section_edit();
    init_de_history();
    init_de_event_listeners();
    if (didg_edition_init != null){
      didg_dimensions = didg_edition_init
    }
    didg_dimension_update_callback();
}

restore_last_didg = function(){
    if (history_id > 0){
        history_id -= 1;
        didg_dimensions = copy_didg(history[history_id]);
        didg_dimension_update_callback(false);
    }
}

restore_next_didg = function(){
    if (history_id + 1 < history.length){
        history_id += 1;
        didg_dimensions = copy_didg(history[history_id]);
        didg_dimension_update_callback(false);
    }
}

push_current_didg_to_history = function(){
    if (history.length > history_id+1) {
        history.splice(history_id + 1)
    }
    history.push(copy_current_didg())
    history_id += 1
}

copy_current_didg = function() {
    return copy_didg(didg_dimensions)
}

copy_didg = function(didg){
    var res = {};
    res['embouchure'] = didg['embouchure'];
    res['sections'] = [];
    var i,sec,copy;
    for(i = 0;i < didg['sections'].length;i++){
        sec = didg['sections'][i];
        copy = [sec[0],sec[1]]
        res['sections'].push(copy)
    }
    return res;
}

update_canvas = function(){
    update_canvas_dimensions();
    draw_didg();
}

coef_dimension_to_canvas = function(){
    var canvas = document.getElementById("didg_canvas");
    var max_diam = get_section_max_diam();
    var length = get_total_length();
    var coef_x,coef_y;
    if (LENGTH_CANVAS_MAX_DEFAULT > length){
        coef_x = canvas.width/(LENGTH_CANVAS_MAX_DEFAULT+LENGTH_CANVAS_PADDING);
    } else {
        coef_x = canvas.width/(length+LENGTH_CANVAS_PADDING);
    }
    if (DIAM_CANVAS_MAX_DEFAULT > max_diam){
        coef_y = canvas.height/(DIAM_CANVAS_MAX_DEFAULT+DIAM_CANVAS_PADDING);
    } else {
        coef_y = canvas.height/(max_diam+DIAM_CANVAS_PADDING);
    }
    return [coef_x,coef_y];
}

click_canvas_to_didg_coord = function(event) {
    var canvas = document.getElementById("didg_canvas")
    var dimension = dimensions_to_canvas();
    var coef = coef_dimension_to_canvas();
    var rel_x = (event.offsetX - (canvas.width - dimension['length']) / 2) / coef[0]
    var rel_y = (event.offsetY - (canvas.height) / 2) / coef[1]
    if (rel_y < 0) {
        rel_y = -rel_y
    }
    return [rel_x,rel_y];
}

dimensions_to_didg_coord = function(){
    var didg_coord = [[0,get_start_diam()/2]];
    var i, x,y;
    x = 0;
    y = get_start_diam()/2;
    for (i=1;i<=nbr_sections();i++){
        x += get_section_length(i);
        y = get_section_end_diam(i)/2;
        didg_coord.push([x,y]);
    }
    return didg_coord;
}

dimensions_to_canvas = function(){
    var coef = coef_dimension_to_canvas();
    var didg_canvas_dimensions = new Object();
    didg_canvas_dimensions['embouchure'] = get_start_diam()*coef[1];
    didg_canvas_dimensions['sections'] = [];
    var i, l, d;
    var length = 0;
    var diam = didg_canvas_dimensions['embouchure'];
    for (i=1;i<=nbr_sections();i++){
        l = get_section_length(i)*coef[0];
        length += l;
        d = get_section_end_diam(i)*coef[1];
        didg_canvas_dimensions['sections'].push([l,d]);
        if (d > diam ) {
            diam = d;
        }
    }
    didg_canvas_dimensions['length'] = length;
    didg_canvas_dimensions['diam'] = diam;
    return didg_canvas_dimensions;
}

dimensions_to_wave_format = function(){
    var i,res = [];
    for (i=1;i<=nbr_sections();i++){
        res.push([get_section_length(i),get_section_end_diam(i-1),get_section_end_diam(i)]);
    }
    return res;
}

update_canvas_dimensions = function(){
    var elem = document.getElementById("didg_canvas_wrapper")
    var max = elem.offsetWidth
    var canvas = document.getElementById("didg_canvas");
    canvas.width = max-DIDG_CANVAS_WIDTH_GAP;
    canvas.height = CANVAS_HEIGHT;
}

draw_didg = function(){
    var canvas = document.getElementById("didg_canvas");
    var dimension = dimensions_to_canvas();
/*
    alert("ok1 " + canvas.width + " " + canvas.height + " "  + dimension['length'] + " " + dimension['diam']);
*/
    var coord = [(canvas.width-dimension['length'])/2,(canvas.height-dimension['embouchure'])/2];
    var ctx = canvas.getContext("2d");
    if (ctx){
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = "#000000";
        var i;
        coord = draw_section(ctx,dimension['sections'][0][0],dimension['embouchure'],dimension['sections'][0][1],coord,1,true);
        for (i=2;i<=nbr_sections();i++){
            coord = draw_section(ctx,dimension['sections'][i-1][0],dimension['sections'][i-2][1],dimension['sections'][i-1][1],coord,i);
        }
        ctx.fillText(get_total_length(),(canvas.width-dimension['length'])/2 + dimension['length']/2 , 13);

        ctx.moveTo((canvas.width-dimension['length'])/2,2);
        ctx.lineTo((canvas.width-dimension['length'])/2+dimension['length'],2);
        ctx.stroke();
    }
}

draw_section = function(ctx,length,d1,d2,coord,id,display_first_diam=false){
    var x = coord[0];
    var y = coord[1];
    ctx.strokeStyle = "#000000";
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(x,y);
    ctx.lineTo(x,y+d1);
    if (display_first_diam){
        ctx.fillText(get_section_end_diam(id-1),x-5 , y+d1 + 13);
    }
    ctx.lineTo(x+length,y+d1+(d2-d1)/2);
    ctx.fillText(get_section_end_diam(id),x+length-5,y+d1+(d2-d1)/2 + 13);
    ctx.lineTo(x+length,y-(d2-d1)/2);
    ctx.lineTo(x,y);
    ctx.fillText(get_section_length(id),x+length/2-5,y-(d2-d1)/4 - 5);
    ctx.stroke();
    if (id == section_edit_id){
        ctx.fillStyle = "#dddddd";
    } else{
        ctx.fillStyle = "#eeeeee";
    }
    ctx.fill();
    ctx.fillStyle = "#000000";
    ctx.beginPath();
    ctx.arc(x,y, 1, 0, 2 * Math.PI);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(x,y+d1, 1, 0, 2 * Math.PI);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(x+length,y+d1+(d2-d1)/2, 1, 0, 2 * Math.PI);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(x+length,y-(d2-d1)/2, 1, 0, 2 * Math.PI);
    ctx.fill();

    return [x+length,y-(d2-d1)/2];
}

update_section_selects = function(){
    update_section_selects_button_display();
    update_section_selects_number_of_rows();
    update_section_select_rows_information();
}

update_section_edition = function(){
    document.getElementById("section_edit_id").innerHTML = section_edit_id;
    update_section_edition_length()
    update_section_edition_width()
    update_section_edition_diam_in()
    update_section_edition_diam_out()
}

update_section_edition_length = function() {
  var elem = document.getElementById("section_edition_length");
  while (elem.length > 0){
    elem.remove(0);
  }
  var coef = coef_length_unit()
  var option;
  var i;
  var max_val = Math.round(LENGTH_MAX*coef);
  for (i = Math.round(LENGTH_MIN*coef); i <= max_val; i++) {
      option = document.createElement("option");
      option.text = i.toString();
      elem.add(option);
  }
  var length = Math.round(coef*get_section_length(section_edit_id));
  elem.value = length
}

update_section_edition_width = function() {
  var elem = document.getElementById("section_edition_width");
  while (elem.length > 0){
    elem.remove(0);
  }
  var option;
  var i;
  var min_val,max_val,diam_in,diam_out,width;
  if (section_edit_id == 1){
    diam_in = get_start_diam();
  } else {
    diam_in = get_section_end_diam(section_edit_id-1);
  }
  diam_out = get_section_end_diam(section_edit_id);
  width = Math.round((diam_out+diam_in)/2)
  min_val = Math.max(width - (diam_in - DIAM_MIN),width - (diam_out - DIAM_MIN))
  if (section_edit_id == 1){
    max_val = Math.min(width + (EMBOUCHURE_MAX - diam_in),width + (DIAM_MAX - diam_out))
  } else {
    max_val = Math.min(width + (DIAM_MAX - diam_in),width + (DIAM_MAX - diam_out))
  }
  for (i = min_val; i <= max_val; i++) {
      option = document.createElement("option");
      option.text = i.toString();
      elem.add(option);
  }
  elem.value = width
}

update_section_edition_diam_in = function() {
  var elem = document.getElementById("section_edition_diam_in");
  while (elem.length > 0){
    elem.remove(0);
  }
  var option;
  var i;
  var max_val;
  var diam;
  if (section_edit_id == 1){
    max_val = EMBOUCHURE_MAX
    diam = get_start_diam();
  } else {
    max_val = DIAM_MAX
    diam = get_section_end_diam(section_edit_id-1);
  }
  for (i = DIAM_MIN; i <= max_val; i++) {
      option = document.createElement("option");
      option.text = i.toString();
      elem.add(option);
  }
  elem.value = diam
}

update_section_edition_diam_out = function() {
  var elem = document.getElementById("section_edition_diam_out");
  while (elem.length > 0){
    elem.remove(0);
  }
  var option;
  var i;
  for (i = DIAM_MIN; i <= DIAM_MAX; i++) {
      option = document.createElement("option");
      option.text = i.toString();
      elem.add(option);
  }
  var diam = get_section_end_diam(section_edit_id);
  elem.value = diam
}

update_section_selects_button_display = function(){
    if (nbr_sections() == 1){
        document.getElementById("remove_section_button").style.display = "none";
    } else {
        document.getElementById("remove_section_button").style.display = "inline";
    }
}

update_section_selects_number_of_rows = function(){
    var nbr_table = nbr_sections_table();
    var nbr_sec = nbr_sections()
    if (nbr_table < nbr_sec){
        var table = document.getElementById("sections_table");
        var i;
        for(i = nbr_table+1 ; i <= nbr_sec ; i++){
            add_table_section_row(table,i);
        }
    } else if (nbr_table > nbr_sec){
        while(nbr_sections_table() > nbr_sections()){
            document.getElementById("sections_table").deleteRow(nbr_sections_table());
        }
    }
}

update_section_select_rows_information = function(){
    var i;
    var coef = coef_length_unit()
    document.getElementById("section_diam_0").value = didg_dimensions['embouchure']
    for (i=0;i<nbr_sections();i++){
        document.getElementById("section_length_" + (i+1)).value = Math.round(didg_dimensions['sections'][i][0]*coef);
        document.getElementById("section_diam_" + (i+1)).value = didg_dimensions['sections'][i][1];
    }
}

add_table_section_row = function(table,id){
    var row = table.insertRow(-1);

    var cell_length = row.insertCell(0);
    var cell_diam = row.insertCell(1);

    cell_length.appendChild(generate_select_length_new_section("section_length_"+ id,LENGTH_DEFAULT,id));
    cell_diam.appendChild(generate_select_diam_new_section("section_diam_"+ id,EMBOUCHURE_DEFAULT,id));
}

generate_select_length_new_section = function(name,default_length,id){
    var select = document.createElement("select");
    select.name = name;
    select.id = name;
    var option;
    var coef=coef_length_unit();
    var i;
    for (i = LENGTH_MIN; i <= LENGTH_MAX*coef; i++) {
        option = document.createElement("option");
        option.text = i.toString();
        select.add(option);
    }
    select.value = Math.round(default_length*coef);
    select.addEventListener("change",function (event){
        set_section_length(Math.round(parseInt(event.currentTarget.value)/coef),id);
        didg_dimension_update_callback();
    });
    return select;
}

generate_select_diam_new_section = function(name,default_diam,id){
    var select = document.createElement("select");
    select.name = name;
    select.id = name;
    var option;
    var i;
    for (i = DIAM_MIN; i <= DIAM_MAX; i++) {
        option = document.createElement("option");
        option.text = i.toString();
        select.add(option);
    }
    select.value = default_diam;
    select.addEventListener("change",function (event){
        set_section_end_diam(parseInt(event.currentTarget.value),id);
        didg_dimension_update_callback();
    });
    return select;
}

add_section = function() {
    insert_section([LENGTH_DEFAULT,get_end_diam()],nbr_sections()+1)
    didg_dimension_update_callback();
}

remove_section = function() {
    if (nbr_sections() > 1){
        if (section_edit_id == nbr_sections()){
          section_edit_id -= 1
        }
        delete_section(nbr_sections())
    }
    didg_dimension_update_callback();
}

update_units = function(){
    var new_unit = document.getElementById("unit_length").value;
    _update_units(new_unit);
}

update_units_section = function(){
    var new_unit = document.getElementById("unit_length_section").value;
    _update_units(new_unit);
}

_update_units = function(new_unit){
    document.getElementById("unit_length").value = new_unit
    document.getElementById("unit_length_section").value = new_unit
    if(length_unit != new_unit){
        length_unit = new_unit;
        var length,i,parent;
        for (i = 1; i<= nbr_sections(); i++){
            length = get_section_length(i);
            parent = document.getElementById("section_length_" + i).parentNode;
            parent.innerHTML = '';
            parent.appendChild(generate_select_length_new_section("section_length_"+ i,length,i));
        }
        update_section_edition_length();
    }

}

nbr_sections = function(){
    return didg_dimensions['sections'].length;
}

nbr_sections_table = function(){

    return $("#sections_table tr").length-1;
}

get_end_diam = function(){
    return didg_dimensions['sections'][nbr_sections()-1][1];
}

get_start_diam = function(){
    return didg_dimensions['embouchure'];
}

set_start_diam = function(diam){
    didg_dimensions['embouchure'] = diam;
}

get_section_end_diam = function(id){
    if (id == 0){
        return get_start_diam();
    }
    return didg_dimensions['sections'][id-1][1];
}

set_section = function(section,id){
    didg_dimensions['sections'][id-1] = section;
}

add_start_section = function(length,embouchure){
    didg_dimensions['sections'].splice(0,0,[length,didg_dimensions['embouchure']]);
    didg_dimensions['embouchure'] = embouchure
}

insert_section = function(section,id){
    didg_dimensions['sections'].splice(id-1,0,section);
}

delete_section = function(id){
  didg_dimensions['sections'].splice(id-1,1);
}

set_section_length = function(length,id){
    didg_dimensions['sections'][id-1][0] = length;
}

set_section_end_diam = function(diam,id){
    didg_dimensions['sections'][id-1][1] = diam;
}

get_section = function(id){
    return didg_dimensions['sections'][id-1];
}

get_section_length = function(id){
    return didg_dimensions['sections'][id-1][0];
}

get_section_max_diam = function(){
    var i,diam = get_start_diam();
    var tmp;
    for (i=1;i<=nbr_sections();i++){
        tmp = get_section_end_diam(i);
        if (tmp > diam){
            diam = tmp;
        }
    }
    return diam;
}

get_total_length = function(){
    var i,l=0;
    for (i=1;i<=nbr_sections();i++){
        l+= get_section_length(i);
    }
    return l;
}

didg_dimension_update_callback = function(update_history=true) {
    if (update_history) {
        push_current_didg_to_history();
    }
    update_section_selects();
    update_section_edition();
    update_canvas();
    udpate_pitch_curves(dimensions_to_wave_format());
}
