
POINT_PADDING = 10
EDGE_PADDING = 6

point_after_didg = function(coord,geometry,diam_min=0){
    if (coord[1] > diam_min && coord[0] > geometry[geometry.length-1][0] + POINT_PADDING){
        return true;
    }
    return false;
}

point_before_didg = function(coord,geometry,diam_min=0,diam_max=1000){
    if (coord[1] > diam_min && coord[1] < diam_max && coord[0] < geometry[0][0] - POINT_PADDING){
        return true;
    }
    return false;
}

point_in_section = function(coord,geometry){
  if(coord[0] > geometry[0][0]){
    var i,m,diff,bound;
    for(i=1;i<geometry.length;i++){
      if(coord[0] < geometry[i][0]){
        m = (geometry[i][1]-geometry[i-1][1])/(geometry[i][0]-geometry[i-1][0])
        diff = coord[0] - geometry[i-1][0];
        bound = geometry[i-1][1] + m*diff;
        if (coord[1] < bound){
          return i;
        }
        return -1;
      }
    }
  }
  return -1;
}

coord_on_point = function(coord,geometry){
    var res = closest_point(coord,geometry);
    if (res[1] < POINT_PADDING){
        return res[0]
    }
    return -1
}

coord_on_edge = function(coord,geometry){
    var res = closest_edge(coord,geometry);
    if (res[1] < EDGE_PADDING){
        return res[0]
    }
    return -1
}

closest_point = function(coord,geometry){
    var i,tmp,min = 10000000, id=-1;
    for (i=0;i<geometry.length;i++){
        tmp = distance_points(coord,geometry[i]);
        if (tmp < min){
            min = tmp;
            id = i;
        }
    }
    return [id,min];
}

closest_edge = function(coord,geometry){
    var i,tmp,min = 10000000, id=-1;
    for (i=0;i<geometry.length-1;i++){
        tmp = distance_edge(coord,geometry[i],geometry[i+1]);
        if (tmp < min){
            min = tmp;
            id = i+1;
        }
    }
    return [id,min];
}

line_equation = function(p1,p2){
    if(p1[0] == p2[0]){
        return [1,0,-p1[0]]
    } else if(p1[1] == p2[1]){
        return [0,1,-p1[1]]
    } else {
        var m = (p1[1] - p2[1])/(p1[0] - p2[0]);
        return [m,-1,-m * p1[0] + p1[1]]
    }
}

distance_points = function(p1,p2){
    return Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2)
}

distance_edge = function(p,edge_1,edge_2){
    if(p[0] < edge_1[0]){
        return distance_points(p,edge_1)
    } else if (p[0] > edge_2[0]){
        return distance_points(p,edge_2)
    } else {
        var coef = line_equation(edge_1,edge_2)
        return Math.abs(coef[0]*p[0] + coef[1]*p[1] + coef[2])/Math.sqrt(coef[0] ** 2 + coef[1] ** 2)
    }
}
